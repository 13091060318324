<template>
  <section class="sponsors">
    <div class="sponsors__content">
      <h1 v-if="sponsors.length" class="sponsors__title">
          Title Sponsors
      </h1>
      <div v-if="sponsors.length" class="sponsors__logos title-sponsors">
        <template v-for="sponsor in sponsors" :key="sponsor.name">
          <a v-if="sponsor.url" :href="sponsor.url" target="_blank">
            <div class="sponsors__item single">
              <div class="sponsors__logo-container nobg">
                <img :src="sponsor.logo" :alt="sponsor.name" :class="sponsor.class || 'full-logo'">
              </div>
              <p class="sponsors__name">
                {{ sponsor.name }}
              </p>
            </div>
          </a>
          <div v-else class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img :src="sponsor.logo" :alt="sponsor.name" :class="sponsor.class || 'full-logo'">
            </div>
            <p class="sponsors__name">
              {{ sponsor.name }}
            </p>
          </div>
        </template>
      </div>
      <h1 class="sponsors__title">
          Partner Sponsors
      </h1>
      <div class="sponsors__logos">
        <template v-for="presenter in presenters" :key="presenter.name">
          <a v-if="presenter.url" :href="presenter.url" target="_blank">
            <div class="sponsors__item single">
              <div class="sponsors__logo-container nobg">
                <img :src="presenter.logo" :alt="presenter.name" :class="presenter.class || 'full-logo'">
              </div>
              <p class="sponsors__name">
                {{ presenter.name }}
              </p>
            </div>
          </a>
          <div v-else class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img :src="presenter.logo" :alt="presenter.name" :class="presenter.class || 'full-logo'">
            </div>
            <p class="sponsors__name">
              {{ presenter.name }}
            </p>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@/styles/components/sponsors.scss';
</style>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const sponsors = ref([
    { logo: require('@/assets/singapore/sponsors/bos.png'), class: 'logo', name: 'BitcoinOS', url: 'https://www.bitcoinos.build/' },
    ]);
    const presenters = ref([
      { logo: require('@/assets/korea/sponsors/burrito.png'), class: 'full-logo', name: 'Burrito Wallet', url: 'https://www.burritowallet.com/en' },
      { logo: require('@/assets/singapore/sponsors/parlaylabs.svg'), class: 'full-logo', name: 'Parlay Labs', url: 'https://www.myparlay.com/' },
      { logo: require('@/assets/singapore/sponsors/OR.png'), class: 'full-logo', name: 'OnRamp', url: 'https://emida.com/' },
      { logo: require('@/assets/korea/sponsors/libera.svg'), class: 'logo libera', name: 'Libera Global AI', url: 'https://liberaglobal.ai/' },
      { logo: require('@/assets/singapore/sponsors/pv.png'), class: 'full-logo', name: 'Persist Ventures', url: 'https://persistventures.com/' },
      { logo: require('@/assets/korea/sponsors/woo.png'), class: 'logo', name: 'WOO', url: 'https://woo.org/' }
    ]);
    return {
      sponsors,
      presenters
    };
  },
};
</script>