<template>
  <section class="sponsors general">
    <div class="sponsors__content">
      <h1 class="sponsors__title">
          Partners
      </h1>
      <div class="sponsors__logos">
        <template v-for="(partner, index) in partners" :key="partner.name">
          <a v-if="partner.url" :href="partner.url" target="_blank">
            <div
              class="sponsors__item single smaller"
              :class="{ 'half-width': index < 2 }"
            >
              <div class="sponsors__logo-container nobg">
                <img :src="partner.logo" :alt="partner.name" :class="partner.class || 'full-logo'">
              </div>
            </div>
          </a>
          <div
            v-else
            class="sponsors__item single smaller"
            :class="{ 'half-width': index < 2 }"
          >
            <div class="sponsors__logo-container nobg">
              <img :src="partner.logo" :alt="partner.name" :class="partner.class || 'full-logo'">
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@/styles/components/sponsors.scss';
</style>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const partners = ref([
      { logo: require('@/assets/singapore/sponsors/aethir.png'), class: 'logo', name: 'Aethir', url: 'https://aethir.com/' },
      { logo: require('@/assets/singapore/sponsors/bos.png'), class: 'logo', name: 'BitcoinOS', url: 'https://www.bitcoinos.build/' },
      { logo: require('@/assets/singapore/sponsors/earnm.svg'), class: 'logo earnm', name: 'Earn\'M', url: 'https://www.earnm.com/' },
      { logo: require('@/assets/singapore/sponsors/forbes.png'), class: 'full-logo', name: 'Forbes Web3', url: 'https://forbes.com/legacy-pass' },
      { logo: require('@/assets/singapore/sponsors/parlaylabs.svg'), class: 'full-logo', name: 'Parlay Labs', url: 'https://www.myparlay.com/' },
      { logo: require('@/assets/singapore/sponsors/OR.png'), class: 'full-logo', name: 'OnRamp', url: 'https://emida.com/' },
      { logo: require('@/assets/singapore/sponsors/coinlist.svg'), class: 'full-logo', name: 'CoinList', url: 'https://coinlist.co/' },
      { logo: require('@/assets/nashville/sponsors/kredete.png'), class: 'logo', name: 'Kredete', url: 'https://www.kredete.com/' },
      { logo: require('@/assets/singapore/sponsors/tidus.svg'), class: 'full-logo', name: 'Tidus', url: 'https://tiduswallet.com/' },
      { logo: require('@/assets/singapore/sponsors/sapien.png'), class: 'full-logo', name: 'Sapien',  url: 'https://game.sapien.io' },
      { logo: require('@/assets/nashville/sponsors/chintai.png'), class: 'full-logo', name: 'Chintai', url: 'https://chintai.io/' },
      { logo: require('@/assets/singapore/sponsors/pv.png'), class: 'full-logo', name: 'Persist Ventures', url: 'https://persistventures.com/' },
      { logo: require('@/assets/singapore/sponsors/mindnet.png'), class: 'full-logo', name: 'Mind Network', url: 'https://www.mindnetwork.xyz/' },
      { logo: require('@/assets/singapore/sponsors/gaib.svg'), class: 'earnm logo', name: 'Gaib', url: 'https://www.gaib.ai/' },
      { logo: require('@/assets/singapore/sponsors/coinbrit.png'), class: 'full-logo', name: 'CoinBrit', url: 'https://coinbrit.news/' },
      { logo: require('@/assets/singapore/sponsors/voc.png'), class: 'full-logo', name: 'Voice Of Crypto', url: 'https://voiceofcrypto.online/' },
      { logo: require('@/assets/singapore/sponsors/vitamin.png'), class: 'full-logo', name: 'Vitamin I', url: 'https://vitamini.io/' },
      { logo: require('@/assets/singapore/sponsors/coliseum.png'), class: 'full-logo', name: 'Coliseum', url: 'https://www.coliseum.org/' },
      { logo: require('@/assets/nashville/sponsors/quai.png'), class: 'full-logo', name: 'Quai Network', url: 'https://qu.ai/' },
      { logo: require('@/assets/korea/sponsors/burrito.png'), class: 'full-logo', name: 'Burrito Wallet', url: 'https://www.burritowallet.com/en' },
      { logo: require('@/assets/korea/sponsors/woo.png'), class: 'logo', name: 'WOO', url: 'https://woo.org/' },
      { logo: require('@/assets/korea/sponsors/libera.svg'), class: 'logo libera', name: 'Libera Global AI', url: 'https://liberaglobal.ai/' },
      { logo: require('@/assets/korea/sponsors/wow.webp'), class: 'logo', name: 'WOW!?', url: 'https://wow.ai/' },
    ]);
    return {
      partners
    };
  },
};
</script>