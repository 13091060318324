<template>
  <Menu buttonText="Register Here" />
  <main id="agenda">
    <iframe
      id="agendaIframe"
      src="https://lu.ma/embed/calendar/cal-OqSQ3tSRwtQHTSE/events?lt=dark"
      width="100%"
      height="1100px"
      frameborder="0"
      style="border-radius: 4px; margin-top: 20px;"
      allowfullscreen=""
      aria-hidden="false"
      tabindex="0">
    </iframe>
  </main>
</template>

<style lang="scss" scoped>
  @import '@/styles/pages/home.scss';
</style>

<script>
// @ is an alias to /src
import Menu from '@/components/singapore/menu';
export default {
  name: 'Agenda',
  components: {
    Menu,
  }
};
</script>