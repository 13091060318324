<template>
  <section class="hero singapore">
      <div class="hero__content container">
        <div class="hero__content--info">
          <h1 class="hero__title">
            DNA HOUSE, Singapore at Mandala Club<br>
            by Aethir, BitcoinOS and DNA
          </h1>
          <h2 class="hero__date">
            September 17th - 19th, 2024
          </h2>
          <a href="http://lu.ma/dnafund" target="_blank">
            <button class="hero__btn">
              Register Now
            </button>
          </a>
          <a href="https://www.google.com/maps/search/?api=1&query=31%20Bukit%20Pasoh%20Rd&query_place_id=ChIJhwLe8m0Z2jERk8HAd9SzPy0" target="_blank">
            <img src="@/assets/singapore/mandala-white.png" class="venue-logo" alt="Mandala Club Logo">
          </a>
        </div>
      </div>
    </section>
  </template>
  
  <script>

  export default {
    name: 'Hero',
    data () {
      return {
      }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
  @import "@/styles/components/hero.scss";
  </style>