<template>
  <section class="about">
    <div class="about__info container">
      <h2 class="about__title">
        About DNA House
      </h2>
      <p class="about__description">
        DNA House is redefining the future of finance, AI, and Web 3 through curated VIP
        experiences at premier events around the world. These exclusive events serve as the
        foundation for building a global community of visionaries, pioneers, investors, and
        disruptive projects. By hosting exclusive events in key cities, we unite the brightest
        minds and most forward-thinking brands, fostering collaboration driving innovation and
        transforming industries. Together, we are shaping the systems of tomorrow, forging
        partnerships that push the boundaries of finance and innovation.
      </p>
    </div>
    <div class="about__rows">
      <div
        v-for="(event, index) in about"
        :key="event.id"
        :class="index % 2 === 0 ? 'about__even' : 'about__odd'"
      >
        <div class="about__content container">
          <div class="about__content--image">
            <img :src="getImagePath(event.image)" :alt="event.subtitle">
          </div>
          <div class="about__content--info">
            <h2 :id="`number-${event.id}`" class="about__number" :class="event.color">
              {{ event.currentNumber }}
            </h2>
            <div class="about__data">
              <h3 class="about__subtitle">
                {{ event.subtitle }}
              </h3>
              <p class="about__body" v-html="event.body"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/about.scss';
</style>

<script>
import { ref, onMounted, nextTick } from 'vue';
import aboutData from '../general/json/about.json';
import { CountUp } from 'countup.js';

export default {
  name: 'About',
  setup() {
    const about = ref([]);

    onMounted(() => {
      about.value = aboutData;
    });

    function getImagePath(imagePath) {
      return require(`@/assets/general/${imagePath}`);
    }

    function animateNumber(elementId, targetNumber) {
      const options = {
        duration: 1.5,
        separator: '',
      };
      const countUp = new CountUp(elementId, targetNumber, options);
      if (!countUp.error) {
        countUp.start();
      } else {
        console.error(countUp.error);
      }
    }

    function observeNumbers() {
      const options = {
        threshold: 1,
      };
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const elementId = entry.target.id;
            const targetNumber = parseInt(entry.target.dataset.number, 10);
            animateNumber(elementId, targetNumber);
            observer.unobserve(entry.target);
          }
        });
      }, options);

      about.value.forEach((event) => {
        const element = document.getElementById(`number-${event.id}`);
        element.dataset.number = event.number;
        observer.observe(element);
      });
    }

    onMounted(async () => {
      about.value = aboutData.map((item) => ({
        ...item,
        currentNumber: 0,
      }));

      await nextTick();
      observeNumbers();
    });
    
    return {
      about,
      getImagePath,
    };
  },
};

</script>