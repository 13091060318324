<template>
  <Menu buttonText="Subscribe to Events" />
  <main id="home">
    <Map />
    <Events />
    <About />
    <Sponsorships />
    <Presenter />
    <Community />
    <Gallery />
    <Partners />
  </main>
</template>

<style lang="scss" scoped>
  @import '@/styles/pages/home.scss';
</style>

<script>
// @ is an alias to /src
import Menu from '@/components/general/menu';
import Map from '@/components/general/map';
import Events from '@/components/general/events';
import About from '@/components/general/about.vue';
import Sponsorships from '@/components/general/sponsorships.vue';
import Gallery from '@/components/general/gallery.vue';
import Presenter from '@/components/general/presenter.vue';
import Community from '@/components/general/community.vue';
import Partners from '@/components/general/partners.vue';

export default {
  name: 'Home',
  components: {
    Menu,
    Map,
    Events,
    About,
    Sponsorships,
    Presenter,
    Gallery,
    Community,
    Partners
  }
};
</script>