<template>
  <Menu buttonText="Register Here" />
  <main id="home">
    <hero />
    <hotel />
    <pictcarousel :images="mandala" />
    <speakers />
    <sponsors />
    <!--<roundtable />
    <parties />-->
    <presentercta />
    <!--<events />-->
  </main>
</template>

<style lang="scss" scoped>
  @import '@/styles/pages/home.scss';
</style>

<script>
// @ is an alias to /src
import Menu from '@/components/singapore/menu';
import hero from '@/components/singapore/hero.vue';
import hotel from '@/components/singapore/hotel.vue';
import pictcarousel from '@/components/singapore/pictcarousel.vue';
import presentercta from '@/components/singapore/presentercta.vue';
import sponsors from '@/components/singapore/sponsors.vue';
import speakers from '@/components/singapore/speakers.vue';
/*import parties from '@/components/singapore/parties.vue';
import roundtable from '@/components/singapore/roundtable.vue';
import events from '@/components/singapore/events.vue';*/

export default {
  name: 'Home',
  components: {
    Menu,
    hero,
    hotel,
    pictcarousel,
    presentercta,
    /*parties,
    roundtable,*/
    sponsors,
    speakers,
   // events
  },
  data() {
    return {
      mandala: [
        require('@/assets/singapore/venue/mandala-dna.png'),
        require('@/assets/singapore/venue/5.png'),
        require('@/assets/singapore/venue/3.png'),
        require('@/assets/singapore/venue/2.png'),
        require('@/assets/singapore/venue/1.png'),
        require('@/assets/singapore/venue/mandala-wide.jpg'),
        require('@/assets/singapore/venue/10.png'),
        require('@/assets/singapore/venue/9.png'),
        require('@/assets/singapore/venue/8.png'),
        require('@/assets/singapore/venue/mandala-cocktail.jpg'),
        require('@/assets/singapore/venue/4.png'),
        require('@/assets/singapore/venue/mandala-den.jpg'),
      ],
    };
  },
};
</script>
