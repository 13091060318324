<template>
  <section class="agenda daythree">
    <div class="agenda__content container">
      <div class="agenda__date">
        <div class="date__container">
          <div>
            <h1 class="date__title">
              Thursday, September 19th
            </h1>
          </div>
          <button class="rotate-btn" v-bind:class="{ 'is-rotated': isRotated, 'iphone-btn': isIphone }"
          @click="seccionVisible = !seccionVisible" v-on:click="toggleRotation">
            <svg :class="{ 'iphone': isIphone }" width="14px" height="9px" viewBox="0 0 18 11">
              <path fill="#3AADCC" d="M9.1,7.8l6.6-6.6C15.8,1.1,16,1,16.1,0.9c0.2,0,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.3,0.2,0.4,0.3
              s0.2,0.3,0.3,0.4C18,1.8,18,2,18,2.2s0,0.4-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.4l-7.5,7.5C9.8,10.8,9.5,11,9.2,11
              c-0.4,0-0.7-0.1-0.9-0.4L0.8,3.1C0.7,3,0.6,2.8,0.5,2.7C0.3,2.5,0.2,2.4,0.2,2.2c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4
              S0.9,1.1,1,1c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0,0.5,0.1C2.3,1,2.4,1.1,2.5,1.2L9.1,7.8z"/>
            </svg>
          </button>
        </div>
        <hr class="date__line">
      </div>
      <transition name="fade">
        <div v-show="seccionVisible">
          <div class="cards__container">
            <div class="cards__single grayblue">
              <p class="cards__title">
                VIP Lunch by Earn'M
              </p>
              <div class="cards__description">
                <p>12:00 PM - 2:00 PM</p>
              </div>
            </div>
            <br class="linebrk">
            <div v-for="(speaker, index) in speakers" :key="index" class="cards__single cerulean" :class="speaker.class">
              <div v-if="speaker.imgClass" :class="'speaker-img ' + speaker.imgClass"></div>
              <div v-if="speaker.imgClass" class="circle"></div>
              <div class="cards__speaker">
                <p class="cards__name">{{ speaker.name }}</p>
                <p class="cards__spktitle" v-html="speaker.title"></p>
              </div>
              <div class="cards__description">
                <p class="time">{{ speaker.time }}</p>
                <p v-if="speaker.location" class="location">{{ speaker.location }}</p>
              </div>
            </div>
          </div>
          <div class="cards__container">
            <div class="cards__single cocktail">
              <p class="cards__title">
                Coliseum Presents: KOLs x Cocktails | An Epic Gaming Gala
              </p>
              <div class="cards__description">
                <p>5:30 PM - 7:30 PM</p>
              </div>
            </div>
            <div class="cards__single dinner">
              <p class="cards__title">
                VIP Dinner by Earn'M
              </p>
              <div class="cards__description">
                <p>7:30 PM - 9:30 PM</p>
              </div>
            </div>
            <div class="cards__single party">
              <p class="cards__title">
                VIP After Party by Earn'M
              </p>
              <div class="cards__description">
                <p>9:30 PM - 1:00 AM</p>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </section>
</template>

<style lang="scss">
  @import '@/styles/components/agenda.scss';
</style>

<script>
export default {
  name: 'daythree',
  data() {
    return {
      seccionVisible: true,
      isRotated: false,
      isIphone: false,
      speakers: [
      {
        imgClass: 'arthur',
        name: 'Arthur Hayes',
        title: 'Co-Founder - BitMEX / CIO - Maelstrom',
        time: '12:00 PM - 12:30 PM',
      },
      {
        imgClass: 'novaes',
        name: 'Dan Novaes',
        title: "CEO & Co-Founder - Earn'M",
        time: '12:30 PM - 1:00 PM',
      },
      {
        imgClass: 'mack',
        name: 'Mack Lorden',
        title: "Aethir Edge",
        time: '2:30 PM - 3:00 PM',
      },
      {
        name: 'Panel 1: The Next Wave of Consumer Web3 Applications',
        title: 'Moderator: Tanya Aggarwal, Marketing at Aethir<br>Mia Mai, Head of Ecosystem at Hashkey<br> Connor Lovely, DePIN Lead at Lotex<br> Mack Lorden, Aethir Edge <br>Austin Barack, Relayer Capital<br> Alessandro De Carli, Co-Founder at Acurast',
        time: '3:00 PM - 3:30 PM',
        class: 'noheight'
      },
      {
        name: 'Panel 2: Are Nodes the New Meta?',
        title: 'Moderator: Kartik Jain, Head of Ecosystem at Aethir<br> Dan Novaes, Co-Founder at EarnM<br> Seb Arz, Co-Founder at Sophon<br>Yukai, CTO at CARV <br> Alex Fazel, Founding Member at Swissborg',
        time: '3:30 PM - 4:00 PM',
        class: 'noheight'
      },
      {
        imgClass: 'tom',
        name: 'Tom McLaughlin',
        title: 'CIO - Coral Invesment Management',
        time: '4:00 PM - 4:30 PM'
      },
      {
        imgClass: 'dan',
        name: 'Dan Mulligan',
        title: 'CEO & Founder - Tidus Wallet',
        time: '4:30 PM - 5:00 PM',
      },
      {
        imgClass: 'miglino',
        name: 'Chris Miglino',
        title: 'CEO, Co-Founder - DNA',
        time: '5:00 PM - 5:30 PM',
      },
      {
        imgClass: 'oliver',
        name: 'Oliver Feldmeier',
        title: 'Founder - Griffin Ai',
        time: '5:30 PM - 6:00 PM',
      }
    ]}
  },
  methods: {
    toggleRotation() {
      this.isRotated = !this.isRotated;
    },
  },
  mounted() {
    this.isIphone = /iPhone/.test(navigator.userAgent);
  }
};
</script>