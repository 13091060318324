<template>
  <div class="morning">
    <div class="grid__title-container">
      <img src="@/assets/nashville/sponsors/roundtable.svg" alt="Roundtable Logo">
      <h2 class="subtitle">
        Interview Series
      </h2>
    </div>
    <div class="grid__container one-container" :style="gridStyle">
      <div class="grid__tracks one" v-if="track1">
        <div class="--title">
          <img src="@/assets/nashville/music/mainstage.png">
        </div>
        <div class="--slot t1" v-for="info in track1" :key="info.company">
          <div class="--slot__time">
            <p>{{ info.start }}</p>
            <p>{{ info.end }}</p>
          </div>
          <p class="--slot__title">
            {{ info.company }}
          </p>
        </div>
      </div>
      <!--<div class="grid__tracks" v-if="track2">
        <div class="--title">
          <img src="@/assets/nashville/music/themil.png">
        </div>
        <div class="--slot t2" v-for="info in track2" :key="info.company">
          <div class="--slot__time">
            <p>{{ info.start }}</p>
            <p>{{ info.end }}</p>
          </div>
          <p class="--slot__title">
            {{ info.company }}
          </p>
        </div>
      </div>
      <div class="grid__tracks" v-if="track3">
        <div class="--title">
          <img src="@/assets/music/rowone.png">
        </div>
        <div class="--slot t3" v-for="info in track3" :key="info.company">
          <div class="--slot__time">
            <p>{{ info.start }}</p>
            <p>{{ info.end }}</p>
          </div>
          <p class="--slot__title">
            {{ info.company }}
          </p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Day One Grid',
  data() {
    return {
      track1: [
        { start: '11:00 AM', end: '11:30 AM', company: 'Chris Sullivan, CMT - Hyperion Decimus' },
        { start: '11:30 AM', end: '11:45 AM', company: 'Zac Townsend, CEO - Meanwhile' },
        { start: '11:45 AM', end: '12:00 PM', company: 'Liliia Nikitchuk, Digital Marketing - Hiveon' },
        { start: '12:00 PM', end: '12:30 PM', company: 'Nuri Katz, Founder - Apex Capital' },
        { start: '12:30 PM', end: '12:45 PM', company: 'Marshall Beard, COO - Gemini' },
        { start: '12:45 PM', end: '1:00 PM', company: 'Sasha Mitchell, Head of Operations - Bel2' },
        { start: '1:00 PM', end: '1:30 PM', company: 'Anjan Katta, Founder & CEO - Daylight' },
        { start: '1:30 PM', end: '1:45 PM', company: 'Anthony Bassilli, Head of Allocators & Tokenization - Coinbase' },
        { start: '1:45 PM', end: '2:00 PM', company: 'Michel Gustavsson, Founder - Bitrefill' },
        { start: '2:00 PM', end: '2:30 PM', company: 'Keynote Speaker: Scott Walker, Co-Founder - DNA' },
        { start: '2:30 PM', end: '3:00 PM', company: ' ' },
        { start: '3:00 PM', end: '3:30 PM', company: 'Keynote Speaker: David Packham, CEO & Founder - Chintai' },
        { start: '3:30 PM', end: '4:00 PM', company: 'Keynote Speaker: Gary Cardone, CEO & Founder - Cardone Digital Ventures' },
        { start: '4:00 PM', end: '4:30 PM', company: 'Keynote Speaker: Tom McLaughlin, CIO - Coral Invesment Management' },
        { start: '4:30 PM', end: '5:00 PM', company: 'Keynote Speaker: Chris Miglino, CEO & Co-Founder - DNA' },
        { start: '5:00 PM', end: '5:30 PM', company: 'Samuel Leichman, Head of Blockchain - Propeller Industries' },
        { start: '5:30 PM', end: '6:00 PM', company: 'Kosta, CEO - Minotaur Trading Systems' },
        { start: '6:00 PM', end: '6:30 PM', company: 'Keynote Speaker: Adeola Adedewe, CEO & Founder - Kredete' }
      ],
      isMobile: false,
    }
  },
  computed: {
    gridStyle() {
      const trackCount = [this.track1].filter(track => track && track.length).length;
      return {
        gridTemplateColumns: `repeat(${trackCount}, minmax(0, 1fr))`
      };
    }
  },
};
</script>
