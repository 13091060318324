<template>
  <section class="presentercta" id="presenter">
    <div class="presentercta__banner">
      <div class="presentercta__banner--content container">
        <div class="presentercta__single-card">
          <div class="text">
            <h2 class="title">Become a Presenter</h2>
            <p class="description">
              Presenters will have access to DNA HOUSE full day on the day of their presentation, which includes:
            </p>
            <ul>
              <li>
                <img src="@/assets/icons/check-dark.png" alt="icon" class="icon" />
                Presentation, $10k
              </li>
              <li>
                <img src="@/assets/icons/check-dark.png" alt="icon" class="icon" />
                Scheduled investor/partner meetings
              </li>
              <li>
                <img src="@/assets/icons/check-dark.png" alt="icon" class="icon" />
                Access to After Party
              </li>
              <li>
                <img src="@/assets/icons/check-dark.png" alt="icon" class="icon" />
                Present your company to our crowd
              </li>
            </ul>
          </div>
          <div class="btn">
            <a href="mailto:sponsors@dna.fund?subject=I want to present" target="_blank">
              <button class="button">Register Here</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/presentercta.scss';
</style>

<script>

export default {
  name: 'presentercta',
  components: {
    
  },
  data() {
    return {
    };
  },
};
</script>