<template>
  <section class="sponsors">
    <div class="sponsors__content">
      <h1 class="sponsors__title">
          Sponsors
      </h1>
      <div class="sponsors__logos">
        <template v-for="sponsor in sponsors" :key="sponsor.name">
          <a v-if="sponsor.url" :href="sponsor.url" target="_blank">
            <div class="sponsors__item single">
              <div class="sponsors__logo-container nobg">
                <img :src="sponsor.logo" :alt="sponsor.name" :class="sponsor.class || 'full-logo'">
              </div>
              <p class="sponsors__name">
                {{ sponsor.name }}
              </p>
            </div>
          </a>
          <div v-else class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img :src="sponsor.logo" :alt="sponsor.name" :class="sponsor.class || 'full-logo'">
            </div>
            <p class="sponsors__name">
              {{ sponsor.name }}
            </p>
          </div>
        </template>
      </div>
      <h1 class="sponsors__title presenting">
          Presenting Sponsors
      </h1>
      <div class="sponsors__logos">
        <template v-for="presenter in presenters" :key="presenter.name">
          <a v-if="presenter.url" :href="presenter.url" target="_blank">
            <div class="sponsors__item single">
              <div class="sponsors__logo-container nobg">
                <img :src="presenter.logo" :alt="presenter.name" :class="presenter.class || 'full-logo'">
              </div>
              <p class="sponsors__name">
                {{ presenter.name }}
              </p>
            </div>
          </a>
          <div v-else class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img :src="presenter.logo" :alt="presenter.name" :class="presenter.class || 'full-logo'">
            </div>
            <p class="sponsors__name">
              {{ presenter.name }}
            </p>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@/styles/components/sponsors.scss';
</style>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const sponsors = ref([
      { logo: require('@/assets/nashville/sponsors/kredete.png'), class: 'logo', name: 'Kredete', url: 'https://www.kredete.com/' },
      { logo: require('@/assets/nashville/sponsors/hemi.png'), class: 'logo', name: 'Hemi', url: 'https://hemi.xyz/' },
      { logo: require('@/assets/nashville/sponsors/tidus.svg'), class: 'full-logo', name: 'Tidus', url: 'https://tiduswallet.com/' },
      { logo: require('@/assets/nashville/sponsors/roundtable.svg'), class: 'full-logo', name: 'TheStreetRoundtable', url: 'https://www.thestreet.com/crypto/' }
    ]);
    const presenters = ref([
      { logo: require('@/assets/nashville/sponsors/quai.png'), class: 'full-logo', name: 'Quai Network', url: 'https://qu.ai/' },
      { logo: require('@/assets/nashville/sponsors/OR.png'), class: 'full-logo', name: 'OnRamp', url: 'https://emida.com/' },
      { logo: require('@/assets/nashville/sponsors/chintai.png'), class: 'full-logo', name: 'Chintai', url: 'https://chintai.io/' },
      { logo: require('@/assets/nashville/sponsors/unbroken.svg'), class: 'full-logo', name: 'Unbroken Chain', url: 'https://unbrokenchain.fund/' },
      { logo: require('@/assets/nashville/sponsors/parlaylabs.svg'), class: 'full-logo', name: 'Parlay Labs', url: 'https://www.myparlay.com/' }
    ]);
    return {
      sponsors,
      presenters
    };
  },
};
</script>