<template>
  <Menu buttonText="Register Here" />
  <main id="home">
    <hero />
    <hotel />
    <pictcarousel :images="belair" />
    <speakers />
    <sponsors />
    <!--<roundtable />
    <parties />-->
    <presentercta />
    <events />
  </main>
</template>

<style lang="scss" scoped>
  @import '@/styles/pages/home.scss';
</style>

<script>
// @ is an alias to /src
import Menu from '@/components/korea/menu';
import hero from '@/components/korea/hero.vue';
import hotel from '@/components/korea/hotel.vue';
import pictcarousel from '@/components/korea/pictcarousel.vue';
import presentercta from '@/components/korea/presentercta.vue';
import sponsors from '@/components/korea/sponsors.vue';
import speakers from '@/components/korea/speakers.vue';
/*import parties from '@/components/korea/parties.vue';
import roundtable from '@/components/korea/roundtable.vue';*/
import events from '@/components/korea/events.vue';

export default {
  name: 'Home',
  components: {
    Menu,
    hero,
    hotel,
    pictcarousel,
    presentercta,
    /*parties,
    roundtable,
    */
    sponsors,
    speakers,
    events
  },
  data() {
    return {
      belair: [
        require('@/assets/korea/venue/building-2.png'),
        require('@/assets/korea/venue/3.png'),
        require('@/assets/korea/venue/1.png'),
        require('@/assets/korea/venue/2.png'),
        require('@/assets/korea/venue/4.png'),
        require('@/assets/korea/venue/5.jpg'),
        require('@/assets/korea/venue/6.jpg'),
        require('@/assets/korea/venue/7.jpg'),
        require('@/assets/korea/venue/8.jpg'),
        require('@/assets/korea/venue/10.jpg'),
        require('@/assets/korea/venue/11.jpg'),
        require('@/assets/korea/venue/9.jpg')
      ],
    };
  },
};
</script>
