<template>
  <div class="morning">
    <div class="grid__title-container">
      <img src="@/assets/nashville/sponsors/roundtable.svg" alt="Roundtable Logo">
      <h2 class="subtitle">
        Interview Series
      </h2>
    </div>
    <div v-if="isMobile" class="mobile slider">
      <carousel>
        <slide :key="1" v-if="track1">
          <div class="grid__tracks">
            <div class="--title">
              <img src="@/assets/nashville/music/mainstage.png">
            </div>
            <div class="--slot t1" v-for="info in track1" :key="info.company">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <!--<slide :key="2" v-if="track2">
          <div class="grid__tracks">
            <div class="--title">
              <img src="@/assets/nashville/music/themil.png">
            </div>
            <div class="--slot t2" v-for="info in track2" :key="info.company">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide> -->
        <slide :key="3" v-if="track3">
          <div class="grid__tracks">
            <div class="--title">
              <img src="@/assets/nashville/music/rowone.png">
            </div>
            <div class="--slot t3" v-for="info in track3" :key="info.company">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
    <div class="grid__container" :style="gridStyle" v-else>
      <div class="grid__tracks" v-if="track1">
        <div class="--title">
          <img src="@/assets/nashville/music/mainstage.png">
        </div>
        <div class="--slot t1" v-for="info in track1" :key="info.company">
          <div class="--slot__time">
            <p>{{ info.start }}</p>
            <p>{{ info.end }}</p>
          </div>
          <p class="--slot__title">
            {{ info.company }}
          </p>
        </div>
      </div>
      <!--<div class="grid__tracks" v-if="track2">
        <div class="--title">
          <img src="@/assets/nashville/music/themil.png">
        </div>
        <div class="--slot t2" v-for="info in track2" :key="info.company">
          <div class="--slot__time">
            <p>{{ info.start }}</p>
            <p>{{ info.end }}</p>
          </div>
          <p class="--slot__title">
            {{ info.company }}
          </p>
        </div>
      </div> -->
      <div class="grid__tracks" v-if="track3">
        <div class="--title">
          <img src="@/assets/nashville/music/rowone.png">
        </div>
        <div class="--slot t3" v-for="info in track3" :key="info.company">
          <div class="--slot__time">
            <p>{{ info.start }}</p>
            <p>{{ info.end }}</p>
          </div>
          <p class="--slot__title">
            {{ info.company }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
  name: 'Day Three Grid',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      track1: [
        { start: '11:00 AM', end: '11:30 AM', company: 'Rob Chang, CEO - Gryphon' },
        { start: '11:30 AM', end: '12:00 PM', company: 'Nick Hanson, CEO - Luxor' },
        { start: '11:45 AM', end: '12:30 PM', company: 'Ro Khanna - US Representative' },
        { start: '12:30 PM', end: '1:00 PM', company: 'Scot Johnson, CEO - Digital Shovel' },
        { start: '1:00 PM', end: '1:15 PM', company: 'Richard Xie, Managing Partner - Krypton Mining' },
        { start: '1:15 PM', end: '2:00 PM', company: 'Smeet Bhatt, Founder - Theya' },
        { start: '3:00 PM', end: '3:30 PM', company: ' ' },
        { start: '3:30 PM', end: '4:00 PM', company: ' ' },
        { start: '4:00 PM', end: '4:30 PM', company: ' ' },
        { start: '4:30 PM', end: '5:00 PM', company: ' ' },
        { start: '5:00 PM', end: '5:30 PM', company: 'Keynote Speaker: Michael Terpin, Founder & CEO - Transform Group, BitAngels' },
        { start: '5:30 PM', end: '5:45 PM', company: ' ' },
        { start: '5:45 PM', end: '6:00 PM', company: 'Willem Schroé, Founder - Botanix Labs' }
      ],
      track3: [
        { start: '11:00 AM', end: '11:30 AM', company: ' ' },
        { start: '11:30 AM', end: '12:00 PM', company: ' ' },
        { start: '11:45 AM', end: '12:30 PM', company: ' ' },
        { start: '12:30 PM', end: '1:00 PM', company: ' ' },
        { start: '1:00 PM', end: '1:15 PM', company: ' ' },
        { start: '1:15 PM', end: '2:00 PM', company: ' ' },
        { start: '3:00 PM', end: '3:30 PM', company: 'Alex Recuso, Founder - CitizenX' },
        { start: '3:30 PM', end: '4:00 PM', company: 'Bobby Zagotta, CEO - BitStamp' },
        { start: '4:00 PM', end: '4:30 PM', company: 'John Stefanopoulos, Founder - Futurebit' },
        { start: '4:30 PM', end: '5:00 PM', company: 'Caitlin Long, CEO - Custodia' },
        { start: '5:00 PM', end: '5:30 PM', company: 'Janet Maingi, COO - Gridless' },
        { start: '5:30 PM', end: '5:45 PM', company: 'Austin Kimm, CEO - Vault' },
        { start: '5:45 PM', end: '6:00 PM', company: '' }
      ],
      isMobile: false,
    }
  },
  computed: {
    gridStyle() {
      const trackCount = [this.track1, this.track3].filter(track => track && track.length).length;
      return {
        gridTemplateColumns: `repeat(${trackCount}, minmax(0, 1fr))`
      };
    }
  },
  mounted() {
    this.isMobile = window.innerWidth < 768;
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 768;
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
