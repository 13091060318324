<template>
  <section class="speakers roundtable">
    <div class="speakers__content container">
      <img src="@/assets/nashville/sponsors/roundtable.svg" alt="Roundtable Logo">
      <h1 class="speakers__title">
        Interview Series
      </h1>
      <div class="speakers__cards">
        <div class="speakers__cards--single"
          v-for="speaker in roundtable"
          :key="speaker.id">
          <a :href="speaker.url" target="_blank" class="speaker-btn">
            <img :src="getImagePath(speaker.image)" :alt="speaker.name">
            <div class="speaker-container">
              <h2>{{ speaker.name }}</h2>
              <h4 v-html="speaker.title"></h4>
            </div>
          </a> 
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue';
import roundtableData from './json/roundtable.json';

export default {
  name: 'Speakers',
  setup() {
    const roundtable = ref(roundtableData);

    function getImagePath(imagePath) {
      return require(`@/assets/nashville/roundtable/${imagePath}`);
    }

    return { roundtable, getImagePath };
  }
};
</script>

<style lang="scss" scoped>
  @import '@/styles/components/speakers.scss';
</style>