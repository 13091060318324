<template>
  <Menu buttonText="Register Here" />
  <main id="entertainment">
    <music />
    <pictcarousel :images="music" />
  </main>
</template>

<style lang="scss" scoped>
  @import '@/styles/pages/home.scss';
</style>

<script>
import Menu from '@/components/korea/menu';
import music from '@/components/korea/music.vue';
import pictcarousel from '@/components/korea/pictcarousel.vue';

export default {
  name: 'Entertainment',
  components: {
    Menu,
    music,
    pictcarousel
  },
  data() {
    return {
      music: [
        require('@/assets/korea/venue/building-2.png'),
        require('@/assets/korea/venue/3.png'),
        require('@/assets/korea/venue/1.png'),
        require('@/assets/korea/venue/2.png'),
        require('@/assets/korea/venue/4.png'),
        require('@/assets/korea/venue/5.jpg'),
        require('@/assets/korea/venue/6.jpg'),
        require('@/assets/korea/venue/7.jpg'),
        require('@/assets/korea/venue/8.jpg'),
        require('@/assets/korea/venue/10.jpg'),
        require('@/assets/korea/venue/11.jpg'),
        require('@/assets/korea/venue/9.jpg')
      ],
    };
  },
};
</script>
