<template>
  <section class="music">
    <div class="music__day">
      <div class="music__text container">
        <h1>Artists Line-Up</h1>
        <p>
          Every night, DNA House in Korea offers an extraordinary music experience like never before.<br>
          Immerse yourself in the beats of live music from world-class DJs who create an energetic and
          vibrant atmosphere, capturing the essence of Korea's dynamic nightlife.
          <br><br>
          DNA House transforms each evening into a festival-like experience, bringing the thrill of a
          large-scale event into an intimate setting.
          <br>
          <b>
            Join us at DNA House in Korea for an evening of exceptional sounds and entertainment.
          </b>
        </p>
      </div>
    </div>
    <div class="music__grid container">
      <div
        v-for="event in music"
        :key="event.id"
        class="music__card"
      >
        <div class="music__image-container">
          <img
            :src="getImagePath(event.image)"
            :alt="event.title"
            class="music__image"
          >
          <img
            :src="getImagePath(event.hover)"
            :alt="event.title + ' Hover'"
            class="music__image-hover"
          >
        </div>
        <h1 class="music__title">{{ event.title }}</h1>
        <div class="music__btn btn">
          <a v-if="event.musicUrl" :href="event.musicUrl" target="_blank" class="button">Play Music</a>
          <a v-if="event.instagramUrl" :href="event.instagramUrl" target="_blank" class="music__icon">
            <img src="@/assets/icons/instagram.svg" alt="Instagram">
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted } from 'vue';
import musicData from '../korea/json/music.json';

export default {
  name: 'Music',
  setup() {
    const music = ref([]);

    onMounted(() => {
      music.value = musicData;
    });

    function getImagePath(imagePath) {
      return require(`@/assets/korea/music/${imagePath}`);
    }

    return {
      music,
      getImagePath,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/components/music.scss";

.music {
  &__day {
    background-image: url('~@/assets/korea/music/background.jpg');
  }
  &__grid {
    max-width: 1480px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px 20px;
    padding: 60px 0;

    @include upto($breakpoint_md) {
      grid-template-columns: 1fr 1fr;
    }

    @include upto($breakpoint_sm) {
      grid-template-columns: 1fr;
    }
  }

  &__card {
    background-color: $dark-gray;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
    position: relative;

    &:hover {
      transform: translateY(-1px);
    }

    &:hover .music__image {
      opacity: 0;
    }

    &:hover .music__image-hover {
      opacity: 1;
    }
  }

  &__image-container {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    height: 0;
    padding-bottom: 75%;
  }

  &__image,
  &__image-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s ease-in-out;
    object-fit: cover;
  }

  &__image-hover {
    opacity: 0;
  }

  &__title {
    margin: 20px 0;
    font-size: 24px;
    color: $white;
  }

  &__btn {
    display: flex;
    justify-content: center;
    gap: 10px;

    .button {
      font-size: 16px;
      padding: 7px 20px;
    }
  }
}
</style>
