<template>
  <section class="hero">
      <div class="hero__content container">
        <div class="hero__content--info">
          <h1 class="hero__title">
            DNA House at Cannery Hall - Nashville, TN
          </h1>
          <h2 class="hero__date">
            July 25th - 27th, 2024
          </h2>
          <a href="http://lu.ma/dnafund" target="_blank">
            <button class="hero__btn">
              Register Now
            </button>
          </a>
          <img src="@/assets/nashville/cannery.svg" class="venue-logo" alt="Cannery Hall Logo">
        </div>
      </div>
    </section>
  </template>
  
  <script>

  export default {
    name: 'Hero',
    data () {
      return {
      }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
  @import "@/styles/components/hero.scss";
  </style>