<template>
  <section class="hotel">
    <div class="hotel__content">
      <div class="hotel__info container">
        <p class="hotel__description bigger-text">
          Welcome to the DNA HOUSE experience at Mandala Club for TOKEN 2049!
        </p>
        <div class="hotel__logos">
          <a href="https://dna.fund/" target="_blank" class="dna">
            <img src="@/assets/dna-white.png">
          </a>
          <a href="https://aethir.com/" target="_blank" class="aethir">
            <img src="@/assets/singapore/sponsors/aethir.png">
          </a>
          <a href="https://www.bitcoinos.build/" target="_blank" class="bos">
            <img src="@/assets/singapore/sponsors/bos.png">
          </a>
        </div>
        <p class="hotel__description">
          Nestled in the vibrant heart of Singapore, Mandala Club is more than just a venue—it's a prestigious
          setting that combines the city's dynamic flair with groundbreaking innovation. As our exclusive
          investors and company owners, you're about to embark on an experience like no other, meticulously
          designed to surpass all expectations.

        </p>
        <p class="hotel__description">
          Immerse yourself in a seamless blend of exquisite cuisine, world-class entertainment, and the authentic
          sophistication of Singaporean hospitality. Prepare to be enveloped by the unique spirit of Singapore,
          enriched by its lively atmosphere and captivating energy.
        </p>
      </div>
      <div class="hotel__cards">
        <div class="hotel__card--content container">
          <div class="card">
            <img src="@/assets/nashville/location/Icon1.svg" alt="Companies Icon">
            <h3 class="card__title">
              <b>18+</b><br>
              Company Presentations
            </h3>
            <p class="card__description">
              Don't miss this opportunity to gain deep knowledge, explore groundbreaking innovations,
              and connect with industry leaders. This event is essential for anyone serious about the
              future of crypto and blockchain technology.
            </p>
          </div>
          <div class="card">
            <img src="@/assets/nashville/location/Icon2.svg" alt="Attendees Icon">
            <h3 class="card__title">
              <b>600+</b><br>
              Attendees
            </h3>
            <p class="card__description">
              Multi-day Event. Along the week you can expect to experience unmatched networking in a Premier
              setting surrounded by Fund Managers, Top-Tier Investors, Crypto Whales and Market Makers
              in a very exclusive atmosphere.

            </p>
          </div>
          <div class="card">
            <img src="@/assets/nashville/location/Icon3.svg" alt="Investors Icon">
            <h3 class="card__title">
              <b>Sponsorship</b><br>
              Opportunities
            </h3>
            <p class="card__description">
              Elevate your brand, connect with industry leaders, and gain exceptional exposure
              in the crypto and blockchain community. Be part of this high-profile gathering
              and drive your business forward.
            </p>
          </div>
        </div>
        <a href="/singapore/sponsorships" class="btn">
          <button class="hotel__btn button">Become a Sponsor</button>
        </a>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/hotel.scss';
</style>

<script>

export default {
  name: 'Hotel',
};

</script>