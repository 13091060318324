<template>
  <Menu buttonText="Register Here" />
  <main id="schedule">
    <div class="btn container">
      <a href="/luma">
        <button class="button">Luma Events</button>
      </a>
    </div>
    <dayone />
    <daytwo />
    <daythree />
  </main>
</template>

<script>
import Menu from '@/components/singapore/menu';
import dayone from '@/components/singapore/agenda/dayone.vue';
import daytwo from '@/components/singapore/agenda/daytwo.vue';
import daythree from '@/components/singapore/agenda/daythree.vue';

export default {
  name: 'Schedule',
  components: {
    Menu,
    dayone,
    daytwo,
    daythree
  },
};
</script>
