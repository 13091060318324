<template>
  <section class="music">
    <div class="music__day">
      <div class="music__text container">
        <h1>Artists Line-Up</h1>
        <p>Every night, DNA House offers an extraordinary musical journey with a selection like never before.
          Experience live music by the talented Virginia band, Wolph and sets from world-class DJs that
          create an energetic and vibrant atmosphere.
          <br><br>
          With three stages with DJs simultaneously, DNA House brings a festival-like experience to each evening.
          <br><br>
          <b>Join us at DNA House for an evening of exceptional sounds and entertainment.</b>
        </p>
      </div>
      <div class="music__title">
        STAGES
      </div>
      <div class="music__logos container">
        <img src="@/assets/nashville/music/mainstage.png">
        <img src="@/assets/nashville/music/rowone.png">
        <img src="@/assets/nashville/music/themil.png">
      </div>
    </div>
    <div
      v-for="(event, index) in music"
      :key="event.id"
      :class="index % 2 === 0 ? 'music__even' : 'music__odd'"
    >
      <div class="music__content container">
        <div class="music__content--image">
          <img :src="getImagePath(event.image)" :alt="event.title">
        </div>
        <div class="music__content--info">
          <h1 class="music__title">
            {{ event.title }}
          </h1>
          <p class="music__description" v-html="event.description"></p>
          <div class="music__btn btn">
            <a v-if="event.musicUrl" :href="event.musicUrl" target="_blank" class="button">Play Music</a>
            <a v-if="event.instagramUrl" :href="event.instagramUrl" target="_blank" class="music__icon">
              <img src="@/assets/icons/instagram.svg" alt="Instagram">
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted } from 'vue';
import musicData from '../nashville/json/music.json';

export default {
  name: 'Music',
  setup() {
    const music = ref([]);

    onMounted(() => {
      music.value = musicData;
    });

    function getImagePath(imagePath) {
      return require(`@/assets/nashville/music/${imagePath}`);
    }

    return { music, getImagePath };
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/components/music.scss";
</style>