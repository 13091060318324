<template>
  <section class="gallery">
    <h2 class="gallery__title">
      Gallery
    </h2>
    <div class="gallery__content container">
      <div
        v-for="(image, index) in images"
        :key="index"
        class="gallery__item"
        :style="{ gridRowEnd: `span ${image.rows}` }"
      >
        <img :src="getImageUrl(image.src)" :alt="image.alt" />
      </div>
    </div>
    <div class="btn gallery__btn">
      <a href="https://www.instagram.com/dna_fund/" target="_blank">
        <button class="button">View More</button>
      </a>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue';

const getImageUrl = (name) => {
  return require(`@/assets/gallery/${name}`);
};

// Define different row spans based on the image proportions
const images = ref([
  { src: '1.jpeg', alt: 'Image 1', rows: 2 },
  { src: '2.jpeg', alt: 'Image 2', rows: 2 },
  { src: '3.jpeg', alt: 'Image 3', rows: 3 },
  { src: '8.png', alt: 'Image 8', rows: 3 },
  { src: '5.jpeg', alt: 'Image 5', rows: 2 },
  { src: '6.jpeg', alt: 'Image 6', rows: 2 },
  { src: '4.jpeg', alt: 'Image 4', rows: 3 },
  { src: '7.jpeg', alt: 'Image 7', rows: 2 },
  { src: '9.jpeg', alt: 'Image 9', rows: 2 },
]);
</script>

<style lang="scss" scoped>
@import "../../styles/import/variables.scss";
@import "../../styles/import/mixins.scss";

.gallery {
  padding: 50px 0;
  background-color: $alt-dark;
  text-align: center;
  &__content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: 100px;
    gap: 10px;
    @include upto ($breakpoint_sm) {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }

  &__title {
    color: $white;
    font-size: 40px;
    font-weight: 500;
    margin: 0 0 40px 0;
    line-height: 46px;
    text-align: center;
    @include upto($breakpoint_md) {
      font-size: 28px;
      line-height: 28px;
    }
  }

  &__item {
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  &__btn {
    margin-top: 30px;
    @include upto ($breakpoint_md) {
      margin-top: 20px;
    }
  }
}
</style>
