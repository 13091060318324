<template>
  <Menu buttonText="Register Here" />
  <main id="entertainment">
    <music />
    <pictcarousel :images="music" />
  </main>
</template>

<style lang="scss" scoped>
  @import '@/styles/pages/home.scss';
</style>

<script>
import Menu from '@/components/nashville/menu';
import music from '@/components/nashville/music.vue';
import pictcarousel from '@/components/nashville/pictcarousel.vue';

export default {
  name: 'Entertainment',
  components: {
    Menu,
    music,
    pictcarousel
  },
  data() {
    return {
      music: [
        require('@/assets/nashville/music/rooms/stage.jpg'),
        require('@/assets/nashville/music/rooms/mainstage.jpg'),
        require('@/assets/nashville/music/rooms/mil.jpg'),
        require('@/assets/nashville/music/rooms/front.jpg'),
        require('@/assets/nashville/music/rooms/row.jpg'),
        require('@/assets/nashville/music/rooms/cannery.jpg'),
      ],
    };
  },
};
</script>
