<template>
  <section class="sponsorships" id="sponsor">
    <div class="bg-video">
      <video autoplay muted loop class="vid">
        <source src="@/assets/nashville/video/bg-nash-2.webm" type="video/webm">
        Your browser does not support the video tag.
      </video>
      <div class="sponsorships__content">
        <h1 class="sponsorships__title">
          DNA House at Cannery Hall - Nashville, TN
        </h1>
        <h2 class="sponsorships__date">
          July 25th - 27th, 2024
        </h2>
        <div class="sponsorships__cards">
          <div v-for="(sponsor, index) in sponsors" :key="index" class="sponsorships__cards--single">
            <div class="benefits">
              <h2>{{ sponsor.title }}</h2>
              <p v-if="sponsor.description"> {{ sponsor.description }}</p>
              <ul>
                <li v-for="(benefit, i) in sponsor.benefits" :key="i">
                  <img src="@/assets/icons/check.png" alt="icon" class="icon" />
                  {{ benefit }}
                </li>
              </ul>
            </div>
            <div class="btn">
              <a :href="sponsor.link" target="_self">
                <button class="button">Become a Sponsor</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue';
import sponsorsData from './json/sponsorships.json';

const sponsors = ref(sponsorsData);
</script>

<style lang="scss" scoped>
  @import '@/styles/components/sponsorships.scss';
</style>