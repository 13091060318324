<template>
  <section class="pic">
    <div class="pic__carousel">
      <Carousel :items-to-show="computedItemsToShow" :wrap-around="true">
        <Slide v-for="(image, index) in images" :key="index">
          <div class="pic__carousel--item">
            <img :src="image" :alt="'Image ' + index">
          </div>
        </Slide>
        <template #addons>
          <Navigation class="carousel-nav"/>
        </template>
      </Carousel>
    </div>
    <div class="pic__cta">
      <generalformcta />
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/pictcarousel.scss';
</style>

<style lang="scss">
@import '@/styles/import/variables.scss';
@import '@/styles/import/mixins.scss';

.pic {
  .carousel__prev,
  .carousel__next {
    background-color: transparent;
    border-radius: 50%;
    &:hover {
      color: #000;
    }
  }
  .carousel-nav {
    z-index: 2;
    @include from ($breakpoint_lg) {
      margin: 0 25px;
    }
  }
}
</style>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css';
import generalformcta from '@/components/nashville/generalformcta.vue';

export default defineComponent({
  name: 'PictureCarousel',
  components: {
    Carousel,
    Slide,
    Navigation,
    generalformcta
  },
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  computed: {
    computedItemsToShow() {
      const width = window.innerWidth;
      if (width < 500) {
        return 1.5;
      } else if (width >= 500 && width < 900) {
        return 2;
      } else if (width >= 900 && width < 1300) {
        return 2.5;
      } else {
        return 3.2;
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.$forceUpdate();
    }
  }
});
</script>
