<template>
  <section class="music">
    <div class="music__day">
      <div class="music__text container">
        <h1>Artists Line-Up</h1>
        <p>
          Every night, DNA House in Singapore invites you to experience an unforgettable musical journey.<br>
          Feel the pulse of live music from renowned DJs, creating an electric vibe that embodies the energy
          of Singapore's lively nightlife.
          <br><br>
          Each evening at DNA House feels like a festival, blending the excitement of a major event with the intimacy of a unique, cozy venue.
          <br>
          <b>
            Come and join us at DNA House in Singapore for a night of exceptional music and entertainment – an experience you won’t want to miss.
          </b>
        </p>
      </div>
    </div>
    <div
      v-for="(event, index) in music"
      :key="event.id"
      :class="index % 2 === 0 ? 'music__even' : 'music__odd'"
    >
      <div class="music__content container">
        <div class="music__content--image">
          <img :src="getImagePath(event.image)" :alt="event.title">
        </div>
        <div class="music__content--info">
          <h1 class="music__title">
            {{ event.title }}
          </h1>
          <p class="music__description" v-html="event.description"></p>
          <div class="music__btn btn">
            <a v-if="event.musicUrl" :href="event.musicUrl" target="_blank" class="button">Play Music</a>
            <a v-if="event.instagramUrl" :href="event.instagramUrl" target="_blank" class="music__icon">
              <img src="@/assets/icons/instagram.svg" alt="Instagram">
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted } from 'vue';
import musicData from '../singapore/json/music.json';

export default {
  name: 'Music',
  setup() {
    const music = ref([]);

    onMounted(() => {
      music.value = musicData;
    });

    function getImagePath(imagePath) {
      return require(`@/assets/singapore/music/${imagePath}`);
    }

    return {
      music,
      getImagePath,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/components/music.scss";

.music {
  &__day {
    background-image: url('~@/assets/singapore/venue/mandala-overlay.png');
    background-position: top;
  }
  &__grid {
    max-width: 1480px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px 20px;
    padding: 60px 0;

    @include upto($breakpoint_md) {
      grid-template-columns: 1fr 1fr;
    }

    @include upto($breakpoint_sm) {
      grid-template-columns: 1fr;
    }
  }

  &__card {
    background-color: $dark-gray;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
    position: relative;

    &:hover {
      transform: translateY(-1px);
    }

    &:hover .music__image {
      opacity: 0;
    }

    &:hover .music__image-hover {
      opacity: 1;
    }
  }

  &__image-container {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    height: 0;
    padding-bottom: 75%;
  }

  &__image,
  &__image-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s ease-in-out;
    object-fit: cover;
  }

  &__image-hover {
    opacity: 0;
  }

  &__title {
    margin: 20px 0;
    font-size: 24px;
    color: $white;
  }

  &__btn {
    display: flex;
    justify-content: center;
    gap: 10px;

    .button {
      font-size: 16px;
      padding: 7px 20px;
    }
  }
}
</style>
