<template>
  <Menu buttonText="Register Here" />
  <main id="home">
    <hero />
    <hotel />
    <pictcarousel :images="cannery" />
    <speakers />
    <sponsors />
    <roundtable />
    <parties />
    <presentercta />
    <events />
  </main>
</template>

<style lang="scss" scoped>
  @import '@/styles/pages/home.scss';
</style>

<script>
// @ is an alias to /src
import Menu from '@/components/nashville/menu';
import hero from '@/components/nashville/hero.vue';
import hotel from '@/components/nashville/hotel.vue';
import pictcarousel from '@/components/nashville/pictcarousel.vue';
import parties from '@/components/nashville/parties.vue';
import presentercta from '@/components/nashville/presentercta.vue';
import sponsors from '@/components/nashville/sponsors.vue';
import roundtable from '@/components/nashville/roundtable.vue';
import speakers from '@/components/nashville/speakers.vue';
import events from '@/components/nashville/events.vue';

export default {
  name: 'Home',
  components: {
    Menu,
    hero,
    hotel,
    pictcarousel,
    presentercta,
    parties,
    sponsors,
    roundtable,
    speakers,
    events
  },
  data() {
    return {
      cannery: [
        require('@/assets/nashville/location/cannery-6.jpg'),
        require('@/assets/nashville/music/rooms/mainstage.jpg'),
        require('@/assets/nashville/music/rooms/stage.jpg'),
        require('@/assets/nashville/location/cannery-3.jpg'),
        require('@/assets/nashville/music/rooms/mil.jpg'),
        require('@/assets/nashville/location/cannery-4.jpeg'),
        require('@/assets/nashville/music/rooms/front.jpg'),
        require('@/assets/nashville/location/cannery-5.jpeg'),
        require('@/assets/nashville/music/rooms/row.jpg'),
        require('@/assets/nashville/location/cannery-7.jpg'),
        require('@/assets/nashville/music/rooms/cannery.jpg')
      ],
    };
  },
};
</script>
