<template>
  <Menu buttonText="Register Here" />
  <main id="entertainment">
    <music />
    <pictcarousel :images="mandala" />
  </main>
</template>

<style lang="scss" scoped>
  @import '@/styles/pages/home.scss';
</style>

<script>
import Menu from '@/components/singapore/menu';
import music from '@/components/singapore/music.vue';
import pictcarousel from '@/components/korea/pictcarousel.vue';

export default {
  name: 'Entertainment',
  components: {
    Menu,
    music,
    pictcarousel
  },
  data() {
    return {
      mandala: [
        require('@/assets/singapore/venue/mandala-dna.png'),
        require('@/assets/singapore/venue/5.png'),
        require('@/assets/singapore/venue/3.png'),
        require('@/assets/singapore/venue/2.png'),
        require('@/assets/singapore/venue/1.png'),
        require('@/assets/singapore/venue/mandala-wide.jpg'),
        require('@/assets/singapore/venue/10.png'),
        require('@/assets/singapore/venue/9.png'),
        require('@/assets/singapore/venue/8.png'),
        require('@/assets/singapore/venue/mandala-cocktail.jpg'),
        require('@/assets/singapore/venue/4.png'),
        require('@/assets/singapore/venue/mandala-den.jpg'),
      ],
    };
  },
};
</script>
