<template>
  <section class="agenda monday">
    <div class="agenda__content container">
      <div class="agenda__date">
        <div class="date__container">
          <div>
            <h1 class="date__title">
              Friday, July 26th
            </h1>
          </div>
          <button class="rotate-btn" v-bind:class="{ 'is-rotated': isRotated, 'iphone-btn': isIphone }"
          @click="seccionVisible = !seccionVisible" v-on:click="toggleRotation">
            <svg :class="{ 'iphone': isIphone }" width="14px" height="9px" viewBox="0 0 18 11">
              <path fill="#3AADCC" d="M9.1,7.8l6.6-6.6C15.8,1.1,16,1,16.1,0.9c0.2,0,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.3,0.2,0.4,0.3
              s0.2,0.3,0.3,0.4C18,1.8,18,2,18,2.2s0,0.4-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.4l-7.5,7.5C9.8,10.8,9.5,11,9.2,11
              c-0.4,0-0.7-0.1-0.9-0.4L0.8,3.1C0.7,3,0.6,2.8,0.5,2.7C0.3,2.5,0.2,2.4,0.2,2.2c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4
              S0.9,1.1,1,1c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0,0.5,0.1C2.3,1,2.4,1.1,2.5,1.2L9.1,7.8z"/>
            </svg>
          </button>
        </div>
        <hr class="date__line">
      </div>
      <transition name="fade">
        <div v-show="seccionVisible">
          <div class="cards__container">
            <div class="cards__single grayblue">
              <p class="cards__title">
                Lunch <br> @ Mainstage
              </p>
              <div class="cards__description">
                <p>12:00 PM - 2:00 PM</p>
              </div>
            </div>
            <br class="linebrk">
            <div v-for="(speaker, index) in speakers" :key="index" class="cards__single cerulean">
              <div :class="'speaker-img ' + speaker.imgClass"></div>
              <div class="circle" v-if="!shouldHideCircle(speaker)"></div>
              <div class="cards__speaker">
                <p class="cards__name">{{ speaker.name }}</p>
                <p class="cards__spktitle">{{ speaker.title }}</p>
              </div>
              <div class="cards__description">
                <p class="time">{{ speaker.time }}</p>
                <p class="location">{{ speaker.location }}</p>
              </div>
            </div>
          </div>
          <dayTwoGrid />
          <div class="cards__container">
            <div class="cards__single cocktail">
              <p class="cards__title">
                Cocktail Hour
                <br>
                @ Mainstage
              </p>
              <div class="cards__description">
                <p>4:00 PM - 7:00 PM</p>
              </div>
            </div>
            <div class="cards__single dinner">
              <p class="cards__title">
                VIP Dinner
                <br>
                @ Mainstage
              </p>
              <div class="cards__description">
                <p>7:00 PM - 9:00 PM</p>
              </div>
            </div>
            <div class="cards__single party">
              <p class="cards__title">
                FRIDAY Party with HEMI and DNA
                <br>
                @ Mainstage
              </p>
              <div class="cards__description">
                <p>9:00 PM - 2:00 AM</p>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </section>
</template>

<style lang="scss">
  @import '@/styles/components/agenda.scss';
</style>

<script>
import dayTwoGrid from './daytwo-grid.vue';


export default {
  name: 'Day One',
  components: {
    dayTwoGrid
  },
  data() {
    return {
      seccionVisible: false,
      isRotated: false,
      isIphone: false,
      speakers: [
      {
        imgClass: 'sigman',
        name: 'Ben Sigman',
        title: 'CEO - Libre',
        time: '2:00 PM - 2:15 PM',
        location: 'Mainstage'
      },
      {
        imgClass: 'drk',
        name: 'Dr. K',
        title: 'Co-Founder - Quai Network',
        time: '2:15 PM - 2:30 PM',
        location: 'Mainstage'
      },
      {
        imgClass: 'unbroken',
        name: 'Unbroken Chain Panel',
        class: 'no-circle',
        title: 'Asher Corson, Domo & Isabel Foxen Duke',
        time: '2:30 PM - 3:00 PM',
        location: 'Mainstage'
      },
      {
        imgClass: 'nick',
        name: 'Nick Mancini',
        title: 'Co-Founder & Chief Product Officer - Parlay Labs',
        time: '3:00 PM - 3:30 PM',
        location: 'Mainstage'
      },
      {
        imgClass: 'tabar',
        name: 'Sam Tabar',
        title: 'CEO - Bit Digital',
        time: '4:00 PM - 4:15 PM',
        location: 'Mainstage'
      },
      {
        imgClass: 'pierce',
        name: 'Brock Pierce',
        title: 'Co-Founder - DNA',
        time: '5:00 PM - 5:30 PM',
        location: 'Mainstage'
      },
      {
        imgClass: 'brill',
        name: 'David Brillembourg',
        title: 'CEO - OnRamp',
        time: '5:30 PM - 6:00 PM',
        location: 'Mainstage'
      },]
    }
  },
  methods: {
    toggleRotation() {
      this.isRotated = !this.isRotated;
    },
    shouldHideCircle(speaker) {
      return speaker.class && speaker.class.includes('no-circle');
    }
  },
  mounted() {
    this.isIphone = /iPhone/.test(navigator.userAgent);
  }
};
</script>
