<template>
  <section class="community">
    <div class="community__content container">
      <div class="community__info">
        <img src="@/assets/icons/tgram.png" alt="Telegram Icon">
        <h3 class="community__title">Join Our Global Telegram Community</h3>
        <div class="btn">
          <a href="https://t.me/dna_fund" target="_blank">
            <button class="button">Join Now</button>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/community.scss';
</style>