<template>
  <div class="morning">
    <div class="grid__title-container">
      <img src="@/assets/nashville/sponsors/roundtable.svg" alt="Roundtable Logo">
      <h2 class="subtitle">
        Interview Series
      </h2>
    </div>
    <div v-if="isMobile" class="mobile slider">
      <carousel>
        <slide :key="1" v-if="track1">
          <div class="grid__tracks">
            <div class="--title">
              <img src="@/assets/nashville/music/mainstage.png">
            </div>
            <div class="--slot t1" v-for="info in track1" :key="info.company">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="2" v-if="track2">
          <div class="grid__tracks">
            <div class="--title">
              <img src="@/assets/nashville/music/themil.png">
            </div>
            <div class="--slot t2" v-for="info in track2" :key="info.company">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="3" v-if="track3">
          <div class="grid__tracks">
            <div class="--title">
              <img src="@/assets/nashville/music/rowone.png">
            </div>
            <div class="--slot t3" v-for="info in track3" :key="info.company">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
    <div class="grid__container" :style="gridStyle" v-else>
      <div class="grid__tracks" v-if="track1">
        <div class="--title">
          <img src="@/assets/nashville/music/mainstage.png">
        </div>
        <div class="--slot t1" v-for="info in track1" :key="info.company">
          <div class="--slot__time">
            <p>{{ info.start }}</p>
            <p>{{ info.end }}</p>
          </div>
          <p class="--slot__title">
            {{ info.company }}
          </p>
        </div>
      </div>
      <div class="grid__tracks" v-if="track2">
        <div class="--title">
          <img src="@/assets/nashville/music/themil.png">
        </div>
        <div class="--slot t2" v-for="info in track2" :key="info.company">
          <div class="--slot__time">
            <p>{{ info.start }}</p>
            <p>{{ info.end }}</p>
          </div>
          <p class="--slot__title">
            {{ info.company }}
          </p>
        </div>
      </div>
      <div class="grid__tracks" v-if="track3">
        <div class="--title">
          <img src="@/assets/nashville/music/rowone.png">
        </div>
        <div class="--slot t3" v-for="info in track3" :key="info.company">
          <div class="--slot__time">
            <p>{{ info.start }}</p>
            <p>{{ info.end }}</p>
          </div>
          <p class="--slot__title">
            {{ info.company }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
  name: 'Day Two Grid',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      track1: [
        { start: '11:00 AM', end: '11:30 AM', company: 'John Divine, Partner - BlockFills' },
        { start: '11:30 AM', end: '11:45 AM', company: 'Michael Wagner - ATMTA/Star Atlas' },
        { start: '11:45 AM', end: '12:00 PM', company: 'Mauricio Di Bartolomeo, Co-Founder - Ledn' },
        { start: '12:00 PM', end: '12:30 PM', company: 'Rahul Mewawalla, CEO - Mawson' },
        { start: '12:30 PM', end: '12:45 PM', company: 'Sam Tabar, CEO - BitDigital' },
        { start: '12:45 PM', end: '1:00 PM', company: 'Bobby Shell, VP of Marketing - Voltage' },
        { start: '1:00 PM', end: '1:30 PM', company: 'Mark E. Blair, Head of Strategy - Bel2' },
        { start: '1:45 PM', end: '2:00 PM', company: 'Mason Jappa, CEO - Blockware Solutions' },
        { start: '2:00 PM', end: '2:15 PM', company: 'Keynote Speaker: Ben Sigman, CEO - Libre' },
        { start: '2:15 PM', end: '2:30 PM', company: 'Keynote Speaker: Dr. K, Co-Founder - Quai Network' },
        { start: '2:30 PM', end: '3:00 PM', company: 'Unbroken Chain Panel: Asher Corson, Domo & Isabel Foxen Duke' },
        { start: '3:00 PM', end: '3:30 PM', company: 'Keynote Speaker: Nick Mancini, Co-Founder & Chief Product Officer - Parlay Labs' },
        { start: '3:30 PM', end: '4:00 PM', company: 'Adil Abdulali, Co-Founder - Samara Asset Management' },
        { start: '4:00 PM', end: '4:15 PM', company: 'Keynote Speaker: Sam Tabar, CEO - Bit Digital' },
        { start: '4:30 PM', end: '5:00 PM', company: 'Keynote Speaker: Tom McLaughlin - DNA' },
        { start: '5:00 PM', end: '5:30 PM', company: 'Keynote Speaker: Brock Pierce, Co-Founder - DNA' },
        { start: '5:30 PM', end: '6:00 PM', company: 'Keynote Speaker: David Brillembourg, CEO - OnRamp' }
      ],
      track2: [
        { start: '11:00 AM', end: '11:20 AM', company: ' ' },
        { start: '11:20 AM', end: '11:40 AM', company: 'Presentation: Nikolai Yakovenko - DeepNewz' },
        { start: '11:40 AM', end: '12:00 PM', company: 'Presentation: Polycarp Nakamoto - Tasedon' },
        { start: '12:00 PM', end: '12:20 PM', company: 'Presentation: Jack Jay - Persist Ventures' },
        { start: '12:20 PM', end: '12:45 PM', company: ' ' },
        { start: '12:45 PM', end: '1:00 PM', company: ' ' },
        { start: '1:00 PM', end: '1:30 PM', company: 'Presentation: Charlie Hu, Co-Founder - Bitlayer Labs' },
        { start: '1:45 PM', end: '2:00 PM', company: ' ' },
        { start: '2:00 PM', end: '2:20 PM', company: 'Presentation: Christopher Perceptions - NoCodeClarity' },
        { start: '2:20 PM', end: '2:40 PM', company: 'Presentation: Marco Mereu - m80' },
        { start: '2:40 PM', end: '3:00 PM', company: 'Presentation: Akshaj Joi' },
        { start: '3:00 PM', end: '3:20 PM', company: 'Presentation: Barry Mezey - KWAN 2.0' },
        { start: '3:40 PM', end: '4:00 PM', company: 'Presentation: Abhi Gupta' },
        { start: '4:00 PM', end: '4:20 PM', company: 'Presentation: Robert Hirsch - Zkalyx' },
        { start: '4:20 PM', end: '4:40 PM', company: 'Presentation: Court Welty - Intrana' },
        { start: '4:40 PM', end: '5:00 PM', company: 'Presentation: Mariana Danilovic - Infiom' }
      ],
      track3: [
        { start: '11:00 AM', end: '11:30 AM', company: ' ' },
        { start: '11:30 AM', end: '11:45 AM', company: ' ' },
        { start: '11:45 AM', end: '12:00 PM', company: ' ' },
        { start: '12:00 PM', end: '12:30 PM', company: ' ' },
        { start: '12:30 PM', end: '12:45 PM', company: ' ' },
        { start: '12:45 PM', end: '1:00 PM', company: ' ' },
        { start: '1:00 PM', end: '1:30 PM', company: ' ' },
        { start: '1:45 PM', end: '2:00 PM', company: ' ' },
        { start: '2:00 PM', end: '2:15 PM', company: ' ' },
        { start: '2:15 PM', end: '2:30 PM', company: ' ' },
        { start: '2:30 PM', end: '3:00 PM', company: 'Presentation: Frank Holmes, Chairman of Board - Hive' },
        { start: '3:00 PM', end: '3:30 PM', company: 'Jeffrey Lucas, CFO - Bitfarms' },
        { start: '3:30 PM', end: '4:00 PM', company: ' ' },
        { start: '4:00 PM', end: '4:30 PM', company: ' ' },
        { start: '4:30 PM', end: '5:00 PM', company: ' ' },
        { start: '5:00 PM', end: '5:30 PM', company: ' ' }
      ],
      isMobile: false,
    }
  },
  computed: {
    gridStyle() {
      const trackCount = [this.track1, this.track2, this.track3].filter(track => track && track.length).length;
      return {
        gridTemplateColumns: `repeat(${trackCount}, minmax(0, 1fr))`
      };
    }
  },
  mounted() {
    this.isMobile = window.innerWidth < 768;
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 768;
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
