<template>
  <section class="hotel">
    <div class="hotel__content">
      <div class="hotel__info container">
        <p class="hotel__description bigger-text">
          Welcome to the DNA HOUSE experience at Cannery Hall for BTC 2024!
        </p>
        <p class="hotel__description">
          Located in the vibrant heart of Nashville, Cannery Hall is more than just a venue—it's an iconic space that fuses
          the city's rich musical heritage with cutting-edge technology. As our VIP guests, you're in for an unparalleled
          experience, carefully curated to exceed your expectations.
        </p>
        <p class="hotel__description">
          Enjoy a blend of incredible food, top-tier entertainment,
          and the genuine warmth of Southern hospitality and style. Whether you're a local or visiting, prepare to be immersed
          in the essence of Nashville, surrounded by its soulful melodies and dynamic energy.
        </p>
      </div>
      <div class="hotel__cards">
        <div class="hotel__card--content container">
          <div class="card">
            <img src="@/assets/nashville/location/Icon1.svg" alt="Companies Icon">
            <h3 class="card__title">
              <b>18+</b><br>
              Company Presentations
            </h3>
            <p class="card__description">
              Don't miss this opportunity to gain deep knowledge, explore groundbreaking innovations,
              and connect with industry leaders. This event is essential for anyone serious about the
              future of crypto and blockchain technology.
            </p>
          </div>
          <div class="card">
            <img src="@/assets/nashville/location/Icon2.svg" alt="Attendees Icon">
            <h3 class="card__title">
              <b>600+</b><br>
              Attendees
            </h3>
            <p class="card__description">
              Multi-day Event. Along the week you can expect to experience unmatched networking in a Premier
              setting surrounded by Fund Managers, Top-Tier Investors, Crypto Whales and Market Makers
              in a very exclusive atmosphere.

            </p>
          </div>
          <div class="card">
            <img src="@/assets/nashville/location/Icon3.svg" alt="Investors Icon">
            <h3 class="card__title">
              <b>Sponsorship</b><br>
              Opportunities
            </h3>
            <p class="card__description">
              Elevate your brand, connect with industry leaders, and gain exceptional exposure
              in the crypto and blockchain community. Be part of this high-profile gathering
              and drive your business forward.
            </p>
          </div>
        </div>
        <a href="/nashville/sponsorships" class="btn">
          <button class="hotel__btn button">Become a Sponsor</button>
        </a>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/hotel.scss';
</style>

<script>

export default {
  name: 'Hotel',
};

</script>