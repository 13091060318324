<template>
  <footer class="main-footer">
    <hr>
    <section class="logo container">
      <div class="logo__container">
        <a href="/">
          <img src="@/assets/dna-house-vertical.png" alt="DNA House Logo">
        </a>
      </div>
      <div class="logo__text">
        <div class="logo__rows">
          <p>To learn more about DNA, visit <a href="http://dna.fund" target="_blank">dna.fund</a></p>
        </div>
        <div class="logo__rows">
          <p>If you have any questions, send us an email at <a href="mailto:contact@dna.fund">contact@dna.fund</a></p>
        </div>
        <div class="logo__social">
          <a href="https://twitter.com/ThisIsDNA" target="_blank">
            <img src="@/assets/icons/x.png" alt="Twitter Icon">
          </a>
          <a href="https://www.instagram.com/dna_fund/" target="_blank">
            <img src="@/assets/icons/insta.png" alt="Instagram Icon">
          </a>
          <a href="https://t.me/dna_fund" target="_blank">
            <img src="@/assets/icons/telegram.png" alt="Telegram Icon">
          </a>
          <a href="https://www.linkedin.com/company/dna-fund" target="_blank">
            <img src="@/assets/icons/linkedin.png" alt="LinkedIn Icon">
          </a>
          <a href="https://www.youtube.com/@dna_fund" target="_blank">
            <img src="@/assets/icons/youtube.png" alt="YouTube Icon">
          </a>
        </div>
      </div>
    </section>
  </footer>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/footer.scss';
</style>

<script>
export default {
  name: 'Footer',
  data() {
    return {
    };
  },
};
</script>