<template>
  <section class="hotel">
    <div class="hotel__content">
      <div class="hotel__info container">
        <p class="hotel__description bigger-text">
          Welcome to the DNA HOUSE experience at BelAir Lounge for The KWB Takeover of DNA House in Seoul, Korea!
        </p>
        <p class="hotel__description">
          In the heart of Seoul, BelAir Lounge stands out as a distinguished venue that fuses the city's vibrant
          energy with cutting-edge sophistication. As our esteemed investors and company owners, you're about
          to enjoy an extraordinary event crafted to surpass all expectations.
        </p>
        <p class="hotel__description">
          Indulge in gourmet cuisine, exceptional entertainment, and the refined elegance of Korean hospitality.
          Immerse yourself in Seoul's unique charm, enriched by its dynamic atmosphere and captivating spirit.
        </p>
      </div>
      <div class="hotel__cards">
        <div class="hotel__card--content container">
          <div class="card">
            <img src="@/assets/nashville/location/Icon1.svg" alt="Companies Icon">
            <h3 class="card__title">
              <b>18+</b><br>
              Company Presentations
            </h3>
            <p class="card__description">
              Don't miss this opportunity to gain deep knowledge, explore groundbreaking innovations,
              and connect with industry leaders. This event is essential for anyone serious about the
              future of crypto and blockchain technology.
            </p>
          </div>
          <div class="card">
            <img src="@/assets/nashville/location/Icon2.svg" alt="Attendees Icon">
            <h3 class="card__title">
              <b>600+</b><br>
              Attendees
            </h3>
            <p class="card__description">
              Multi-day Event. Along the week you can expect to experience unmatched networking in a Premier
              setting surrounded by Fund Managers, Top-Tier Investors, Crypto Whales and Market Makers
              in a very exclusive atmosphere.

            </p>
          </div>
          <div class="card">
            <img src="@/assets/nashville/location/Icon3.svg" alt="Investors Icon">
            <h3 class="card__title">
              <b>Sponsorship</b><br>
              Opportunities
            </h3>
            <p class="card__description">
              Elevate your brand, connect with industry leaders, and gain exceptional exposure
              in the crypto and blockchain community. Be part of this high-profile gathering
              and drive your business forward.
            </p>
          </div>
        </div>
        <a href="/korea/sponsorships" class="btn">
          <button class="hotel__btn button">Become a Sponsor</button>
        </a>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/hotel.scss';
</style>

<script>

export default {
  name: 'Hotel',
};

</script>