<template>
  <Menu buttonText="Register Here" />
  <main id="schedule">
    <dayone />
    <daytwo />
    <daythree />
  </main>
</template>

<script>
import Menu from '@/components/korea/menu';
import dayone from '@/components/korea/agenda/dayone.vue';
import daytwo from '@/components/korea/agenda/daytwo.vue';
import daythree from '@/components/korea/agenda/daythree.vue';

export default {
  name: 'Schedule',
  components: {
    Menu,
    dayone,
    daytwo,
    daythree
  },
};
</script>
