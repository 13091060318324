<template>
  <section class="hero korea">
      <div class="hero__content container">
        <div class="hero__content--info">
          <h1 class="hero__title">
            DNA HOUSE at BelAir Lounge<br>
            Seoul, Korea
          </h1>
          <h2 class="hero__date">
            September 3rd - 5th, 2024			
          </h2>
          <a href="http://lu.ma/dnafund" target="_blank">
            <button class="hero__btn">
              Register Now
            </button>
          </a>
          <a href="https://www.google.com/maps/search/37.524298,+127.041927?entry=tts&g_ep=EgoyMDI0MDcyOS4xKgBIAVAD" target="_blank">
            <img src="@/assets/korea/logo.png" class="venue-logo" alt="Bellevue Logo">
          </a>
        </div>
      </div>
    </section>
  </template>
  
  <script>

  export default {
    name: 'Hero',
    data () {
      return {
      }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
  @import "@/styles/components/hero.scss";
  </style>