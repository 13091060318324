<template>
  <header class="main-menu">
    <div class="container">
      <section class="main-menu__logo">
        <a href="/" class="logo">
          <img src="@/assets/dna-house.png">
        </a>
        <button class="menu-toggle" @click="toggleMenu">
          <span v-if="!isMenuOpen">&#9776;</span>
          <span v-else>&times;</span>
        </button>
        <div class="overlay" v-if="isMenuOpen" @click="toggleMenu"></div>
          <div class="main-menu__links" v-bind:class="{ open: isMenuOpen }">
            <a :class="{ active: isActiveRoute('/') }" href="/">
              Home
            </a>
            <a href="/#presenter">
              Presenters
            </a>
            <a href="/#sponsor">
              Sponsorships
            </a>
            <a href="https://t.me/dna_fund" target="_blank">
              Join Our Telegram
            </a>
            <generalformcta :buttonText="buttonText"/>
        </div>
      </section>
    </div>
  </header>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/menu.scss';
</style>

<script>
import generalformcta from '@/components/singapore/generalformcta.vue';

export default {
  name: 'main menu',
  data() {
    return {
      isMenuOpen: false
    };
  },
  components: {
    generalformcta
  },
  props: {
    buttonText: String
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    isActiveRoute(route) {
      return this.$route.path === route;
    }
  }
};
</script>

<style lang="scss">
@import '@/styles/import/variables.scss';
@import '@/styles/import/mixins.scss';

  .main-menu {
    .formcta {
      &__btn {
        padding: 13px 20px;
        @include between ($breakpoint_lg, $breakpoint_xl) {
          padding: 10px 12px;
        }
        @include upto (650px) {
          margin: 0;
          padding: 8px 40px;
        }
      }
    }
  }
</style>