<template>
  <section class="events parties">
    <div class="events__content container">
      <h1 class="events__title">
        Party the Night Away
      </h1>
      <h2 class="events__subtitle">
          Get ready our epic DNA HOUSE parties in Nashville! Join us for an evening
          filled with great music, good vibes, and Southern hospitality.
          Make your way to one of our 3 stages at Cannery Hall; Mainstage,
          The Mil and RowONe, with live music all night long.
          <br><br>
          <div class="btn">
            <a href="/nashville/entertainment">
              <button class="button">Meet The Band and DJs</button>
            </a>
          </div>
          <sub>*ID will be required at the entrance</sub>
      </h2>
      <div class="events__cards">
        <div class="events__cards--single"
          v-for="event in parties" :key="event.id">
          <a :href="event.url" target="_blank">
            <div class="event-content">
              <img :src="getImagePath(event.image)" :alt="event.name">
              <h2>{{ event.title }}</h2>
              <h4 v-html="event.date"></h4>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue';
import partiesData from './json/parties.json';

export default {
  name: 'parties',
  setup() {
    const parties = ref(partiesData);


    function getImagePath(imagePath) {
      return require(`@/assets/nashville/parties/${imagePath}`);
    }

    return { parties, getImagePath };
  }
};
</script>

<style lang="scss" scoped>
  @import '@/styles/components/events.scss';
</style>