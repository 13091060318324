<template>
  <section class="events">
    <div class="events__content container">
      <h1 class="events__title">
        Follow the DNA House around the world, these are our upcoming events. 
      </h1>
      <h2 class="events__subtitle">
        Details will be revealed soon, but in the meantime, don't miss out on exciting
        sponsorship opportunities and exclusive presenting spaces. To learn more and secure
        your spot, please email us at <a href="mailto:events@dna.fund">events@dna.fund</a>.
        <br>
        <b>Be part of this unparalleled experience and connect with industry leaders and innovators!</b>
      </h2>
      <div class="events__cards">
        <div class="events__cards--single"
          v-for="event in events" 
          :key="event.id"
          :class="{ 'clickable': event.url }">
          <a v-if="event.url" :href="event.url" class="event-link">
            <div class="event-content">
              <img :src="getImagePath(event.image)" :alt="event.name">
              <h2>{{ event.title }}</h2>
              <h4 v-html="event.date"></h4>
            </div>
          </a>
          <div v-else class="event-content">
            <img :src="getImagePath(event.image)" :alt="event.name">
            <h2>{{ event.title }}</h2>
            <h4 v-html="event.date"></h4>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue';
import eventsData from './json/events.json';

export default {
  name: 'events',
  setup() {
    const events = ref(eventsData);


    function getImagePath(imagePath) {
      return require(`@/assets/nashville/events/${imagePath}`);
    }

    return { events, getImagePath };
  }
};
</script>

<style lang="scss" scoped>
  @import '@/styles/components/events.scss';
</style>